<template>
  <b-card>
    <b-row>
      <b-col
        cols="12"
        md="6"
      >
        <h4 class="card-title mb-0 pt-50">
          View Timesheet</h4>
      </b-col>
      <b-col
        cols="12"
        md="6 ml-auto"
      >
        <div class="d-flex align-items-center justify-content-end">
          <b-button
            variant="outline-secondary"
            @click="back"
          >
            Back
          </b-button>
        </div>
      </b-col>
    </b-row>
    <hr>
    <b-form>
      <b-row
        v-if="$store.state.selectedUser.parent_type !== 'Employee' && $store.state.selectedUser.parent_type !== ''"
        class="mt-1"
      >
        <b-col cols="6">
          <b-form-group
            label="Employee Name"
            label-cols-md="4"
            label-align="right"
          >
            <span
              class="form-control"
              v-text="timesheet.name"
            />
          </b-form-group>
        </b-col>
        <b-col cols="6">
          <b-form-group
            label="Timesheet Frequency"
            label-cols-md="4"
            label-align="right"
          >
            <span
              class="form-control"
              v-text="timesheet.timesheet_frequency"
            />
          </b-form-group>
        </b-col>
      </b-row>
      <b-row>
        <b-col cols="6">
          <b-form-group
            label="Month"
            label-cols-md="4"
            label-align="right"
          >
            <span
              class="form-control"
              v-text="month"
            />
          </b-form-group>
        </b-col>
        <b-col
          v-if="isShowWeeks"
          cols="6"
        >
          <b-form-group
            label="Week"
            label-cols-md="4"
            label-align="right"
          >
            <span
              class="form-control"
              v-text="week"
            />
          </b-form-group>
        </b-col>
      </b-row>
      <b-row v-if="isShowWeeks">
        <b-col cols="12">
          <dl class="row">
            <dt class="col-sm-2 text-right mt-50">
              Day's
            </dt>
            <dd class="col-sm-10">
              <table class="table table-striped table-bordered mb-0">
                <thead>
                  <tr>
                    <th>Sun {{ sun_day }}</th>
                    <th>Mon {{ mon_day }}</th>
                    <th>Tue {{ tue_day }}</th>
                    <th>Wed {{ wed_day }}</th>
                    <th>Thu {{ thu_day }}</th>
                    <th>Fri {{ fri_day }}</th>
                    <th>Sat {{ sat_day }}</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>
                      <label
                        class="form-control"
                        v-text="timesheet.sunday.hours"
                      />
                    </td>
                    <td>
                      <label
                        class="form-control"
                        v-text="timesheet.monday.hours"
                      />
                    </td>
                    <td>
                      <label
                        class="form-control"
                        v-text="timesheet.tueday.hours"
                      />
                    </td>
                    <td>
                      <label
                        class="form-control"
                        v-text="timesheet.wedday.hours"
                      />
                    </td>
                    <td>
                      <label
                        class="form-control"
                        v-text="timesheet.thusday.hours"
                      />
                    </td>
                    <td>
                      <label
                        class="form-control"
                        v-text="timesheet.friday.hours"
                      />
                    </td>
                    <td>
                      <label
                        class="form-control"
                        v-text="timesheet.satday.hours"
                      />
                    </td>
                  </tr>
                </tbody>
              </table>
            </dd>
          </dl>
        </b-col>
      </b-row>
      <b-row>
        <b-col cols="6">
          <b-form-group
            label="Total Hours"
            label-cols-md="4"
            label-align="right"
          >
            <span
              class="form-control"
              v-text="timesheet.total_hours"
            />
          </b-form-group>
        </b-col>
        <b-col
          v-show="User.profile().allow_timesheet_parents[0].is_expenses_exist"
          cols="6"
        >
          <b-form-group
            label="Expenses"
            label-cols-md="4"
            label-align="right"
          >
            <span class="form-control">{{ timesheet.expenses | currency('$ ', 2, { thousandsSeparator: ',' }) }}</span>
          </b-form-group>
        </b-col>
        <b-col cols="6">
          <b-form-group
            label="Status"
            label-cols-md="4"
            label-align="right"
          >
            <span
              class="form-control"
              v-text="timesheet.status"
            />
          </b-form-group>
        </b-col>
        <b-col
          v-if="timesheet.reject_reason != null && timesheet.status === 'Rejected'"
          cols="6"
        >
          <b-form-group
            label="Reject Reason"
            label-cols-md="4"
            label-align="right"
          >
            <b-form-textarea
              v-model="timesheet.reject_reason"
              disabled
              rows="2"
            />
          </b-form-group>
        </b-col>
      </b-row>
      <hr>

      <div class="d-flex mt-2">
        <h4 class="mb-0 ml-50">
          Documents
        </h4>
      </div>
      <b-row
        v-if="timesheet.documents.length !== 0"
        class="mt-1"
      >
        <b-col
          cols="11"
          offset="1"
        >
          <b-link
            v-for="option in timesheet.documents"
            :key="option.id"
            :value="option.id"
            :href="option.attachment_link"
            :title="option.name"
            target="option.attachment_link"
            :class="{'mt-75': option}"
          >
            <b-badge
              variant="primary"
              class="mr-1"
            >
              <span>{{ option.name }}</span>
            </b-badge>
          </b-link>
        </b-col>
      </b-row>
      <div
        v-if="timesheet.documents.length === 0"
        class="text-center"
      >
        <p> No Documents available </p>
      </div>
      <hr>
    </b-form>
  </b-card>
</template>

<script>
import {
  BCard, BRow, BCol, BForm, BFormGroup, BButton, BBadge, BFormTextarea, BLink,
} from 'bootstrap-vue'
import Timesheet from '@/js/timesheets'
import Constants from '@/js/constant'
import moment from 'moment'
import User from '@/js/user'

export default {
  components: {
    BCard,
    BRow,
    BCol,
    BForm,
    BLink,
    BFormGroup,
    BButton,
    BBadge,
    BFormTextarea,
  },
  directives: {
  },
  data() {
    return {
      User,
      timesheet: {
        documents: [],
      },
      sun_day: null,
      mon_day: null,
      tue_day: null,
      wed_day: null,
      thu_day: null,
      fri_day: null,
      sat_day: null,
      week: '',
      month: '',
      isShowWeeks: false,
      isShowDays: false,
    }
  },
  mounted() {
  },
  created() {
    // if (!User.profile().timesheet_provider_parents.length > 0) {
    //   if (User.profile().job_exist) {
    //     window.location.hash = '#/jobs'
    //   } else if (User.profile().invoice_exist) {
    //     window.location.hash = '#/invoices'
    //   } else {
    //     Auth.logout()
    //   }
    //   return
    // }
    Timesheet.getTimeSheetById(this, this.$route.params.id)
  },
  destroyed() {
  },
  methods: {
    setTimeSheet(timesheet) {
      this.timesheet = timesheet
      this.month = moment(timesheet.month_date, 'MM-DD-YYYY').format('MMM-YYYY')
      if (timesheet.timesheet_frequency === 'weekly') {
        this.isShowWeeks = true
        this.isShowDays = true
        this.week = `${timesheet.week_start_date} - ${timesheet.week_end_date}`
        this.sun_day = moment(Constants.getDateStringFromEprmDate(timesheet.sunday.date), 'MM-DD-YYYY').format('MM/DD')
        this.mon_day = moment(Constants.getDateStringFromEprmDate(timesheet.monday.date), 'MM-DD-YYYY').format('MM/DD')
        this.tue_day = moment(Constants.getDateStringFromEprmDate(timesheet.tueday.date), 'MM-DD-YYYY').format('MM/DD')
        this.wed_day = moment(Constants.getDateStringFromEprmDate(timesheet.wedday.date), 'MM-DD-YYYY').format('MM/DD')
        this.thu_day = moment(Constants.getDateStringFromEprmDate(timesheet.thusday.date), 'MM-DD-YYYY').format('MM/DD')
        this.fri_day = moment(Constants.getDateStringFromEprmDate(timesheet.friday.date), 'MM-DD-YYYY').format('MM/DD')
        this.sat_day = moment(Constants.getDateStringFromEprmDate(timesheet.satday.date), 'MM-DD-YYYY').format('MM/DD')
      } else {
        this.isShowWeeks = false
        this.isShowDays = false
      }
    },
    back() {
      this.$router.push('/timesheets').catch(() => {})
    },
  },
  setup() {
  },
}
</script>

<style lang="scss">
</style>
